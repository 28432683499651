import globalData from "./data.json";
import pos from "./pos.json";

export const formatName = (name) => {
  // 检查是否是英文名
  if (/^[a-zA-Z\s]+$/.test(name)) {
    return name; // 英文名直接返回
  }

  if (name === "Army 陶") {
    return "Army *";
  }

  // 中文名处理
  const length = name.length;

  return name[0] + " " + "* ".repeat(length - 1);
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};
export const isMobileSize = () => {
  return window.innerWidth < 1200;
};

export const isWeixin = () => {
  return /MicroMessenger/i.test(navigator.userAgent);
};

export function hideLastTwoDigits(phoneNumber) {
  const str = String(phoneNumber);
  return str.slice(0, str.length - 2) + "**";
}

function getRandom(n, m) {
  var num = Math.floor(Math.random() * (m - n + 1) + n);
  return num;
}

const truncateCityName = (name) => {
  if (!name) {
    return;
  }
  return name.length > 4 ? name.slice(0, 5) + ".." : name;
};

export function getCountrys() {
  const buffers = [];
  for (let i = 0; i < globalData.length; i++) {
    const countryName = globalData[i].country;
    if (buffers.indexOf(countryName) > -1) {
      continue;
    } else {
      buffers.push(countryName);
    }
  }
  return buffers;
}

getCountrys();
const generateRandomData = () => {
  return Array.from({ length: getCountrys().length }, () =>
    Math.floor(Math.random() * 100 + 300)
  );
};
export function getSeries() {
  return getCountrys().map((name) => [
    {
      name,
      type: "bar",
      stack: "总量",
      label: {
        show: true,
        position: "insideRight",
      },
      data: generateRandomData(),
    },
  ]);
}

export function getCitys() {
  let cityList = [];
  let countries = getCountrys();

  countries.map((name) => {
    const matchData = globalData.filter((item) => item.country === name);
    matchData.forEach((data) => {
      if (data.city) {
        cityList.push({
          countryName: truncateCityName(data.country),
          cityName: truncateCityName(data.city),
          weight: getRandom(15, 20),
        });
      }
    });
  });

  return cityList;
}
// 标注点坐标
export const getPoints = () => {
  const buffer = pos.forEach((item) => {});
};

// 注册的城市
export const checkCity = ["威海", "日照", "成都"];

export const viewport = () => {
  var designWidth = 2640; // 设计稿宽度
  var viewport = document.querySelector("meta[name=viewport]");

  // 定义缩放函数
  function setScale() {
    var scale = 0.5;
    viewport.setAttribute(
      "content",
      "width=" + designWidth + ", initial-scale=" + scale
    );
  }

  // 初次加载时设置缩放比例
  setScale();

  // 监听窗口调整大小事件（包括屏幕旋转）
  window.addEventListener("resize", function () {
    setScale();
  });
};

export const getCityByCountry = (name) => {
  return globalData.filter((item) => item.country === name);
};
export const flagName = {
  CN: "中国",
  US: "美国",
  DE: "德国",
  LK: "斯里兰卡",
  PY: "巴拉圭",
  BR: "巴西",
  AE: "阿联酋",
  SA: "沙特",
  EG: "埃及",
  RU: "俄罗斯",
  KH: "柬埔寨",
  VN: "越南",
  TH: "泰国",
  MM: "缅甸",
  ID: "印尼",
  MY: "马来西亚",
  KE: "肯尼亚",
  UG: "乌干达",
  TZ: "坦桑尼亚",
  ZA: "南非",
  LB: "黎巴嫩",
  KR: "韩国",
  DK: "丹麦",
};
export const getCountryFlagBackgrounds = () => {
  const obj = {};
  Object.keys(flagName).map((name) => {
    obj[name] = {
      width: 15,
      backgroundColor: {
        image: `https://xunfutech.oss-cn-beijing.aliyuncs.com/website/images/flag/${name.toLowerCase()}.png`,
      },
    };
  });
  return obj;
};

export const getFlagPics = () => {
  return Object.keys(flagName).map(
    (name) =>
      `https://xunfutech.oss-cn-beijing.aliyuncs.com/website/images/flag/${name.toLowerCase()}.png`
  );
};
const getScale = () => {
  if (window.innerHeight > 1500) {
    return 1;
  } else if (window.innerHeight > 1000) {
    return 0.8;
  }
  return 0.5;
};

export const ScaleOffset = getScale();

export const autoHeight = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    console.log(vh);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const isIOS = () => /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
