import React, { Suspense, lazy } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import ErrorBoundary from "./components/ErrorBoundary"; // 导入 ErrorBoundary 组件
import { isMobile, isMobileSize } from "./utils/helper";
import "./App.scss";

const Report = lazy(() => import("./pages/Report/Report"));

const Complaints = lazy(() => import("./pages/Complaints/Complaints"));
const ComplaintsDetail = lazy(() => import("./pages/Complaints/Detail"));

const ArticleList = lazy(() => import("./pages/Article/ArticleList"));
const ArticlePage = lazy(() => import("./pages/Article/ArticlePage"));

const City = lazy(() => import("./pages/City/City"));

const UserAgreement = lazy(() => import("./pages/UserAgreement/UserAgreement"));
const UserPrivacy = lazy(() => import("./pages/UserPrivacy/UserPrivacy"));
const DownLoad = lazy(() => import("./pages/Download/download"));

const Home = isMobile()
  ? lazy(() => import("./pages/Mobile/Entry"))
  : lazy(() => import("./pages/Entry/Entry"));

const Us = isMobile()
  ? lazy(() => import("./pages/Mobile/AboutUs"))
  : lazy(() => import("./pages/Entry/AboutUs"));

function App() {
  return (
    <div className="app">
      <HashRouter
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
        <ErrorBoundary>
          <Suspense>
            <Routes>
              {/*--------------- 官网 -------------*/}
              <Route path="/" element={<Home />} />
              <Route path="/us" element={<Us />} />
              <Route path="/article/list" element={<ArticleList />} />
              <Route path="/article/:id" element={<ArticlePage />} />
              {/*--------------- 询赋文化 -------------*/}

              <Route path="city" element={<City />} />

              {/*--------------- 询赋 webview-------------*/}
              <Route path="report" element={<Report />} />
              <Route path="userAgreement" element={<UserAgreement />} />
              <Route path="userPrivacy" element={<UserPrivacy />} />
              <Route path="download" element={<DownLoad />} />
              <Route path="complaints/detail" element={<ComplaintsDetail />} />
              <Route path="complaints" element={<Complaints />} />
              {/*--------------- 其他 -------------*/}
              <Route path="*" element={<div className="dom-404"></div>} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </HashRouter>
    </div>
  );
}

export default App;
